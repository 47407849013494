
.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0rem;
    padding-right: 0rem;

    &.navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;

        @include media-breakpoint-down(sm) {
            margin-left: 10px !important;
        }
    }

    &.navbar-expand-lg .search-ul {
        @include media-breakpoint-down(md) {
            margin-left: auto !important;
            padding-right: 15px;
        }
    }

    &.navbar-expand-lg.tiptop-navbar {
        background-image: url($primary-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-bottom: .4rem solid $white;
        display: none;
    }

    &.global-navbar {
        background-image: url($secondary-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        flex-flow: wrap;
        clear: both;
        -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);

        @media (max-width: 1400px) {
            background-image: url($small-nav-background);
        }

        .brand-logo img {
            width: 200px;

            @media (max-width: 1400px) {
                width: 200px;
            }
        }

        li:hover {
            font-style: normal;
            letter-spacing: normal;
        }

        li::after {
            display: none;
        }

        @media (max-width: 650px) {
            //display: none;
        }
    }

    &.mobile-nav {
        position: fixed;
        width: 100%;
        background-image: url($mobile-nav-background);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .dropdown-menu {
        background-color: $navbar-dropdown-color;
        top: 52; /*  height of nav-item  */
        left: 15px;
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;
        text-transform: none;
        margin: 0;
        border-radius: 0;
        border: none;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        position: static;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                font-weight: 500;
            }
        }

        p {
            font-family: $font-family-condensed;
        }

        h3 {
            font-weight: 800;
            font-style: italic;
            font-size: 2rem;
            text-transform: uppercase;
        }

        h5 {
            font-weight: 800;
            font-family: $font-family-compressed;
        }


        li::after {
            content: none;
        }

        li {
            &:last-child::after {
                display: none;
            }
        }

        .container .row {
            margin-right: 0;
        }

        div[class*="col"] {
            margin-bottom: 0.5rem;
        }

        .dropdown-title {
            font-size: 1.3rem;
            font-weight: 500;
            padding-left: 0.7rem;

            &.dropdown-title-col2 {
                padding-left: 0rem;
            }
        }

        .product-categories {
            list-style: none;
            padding-left: 0px;
            margin-top: 1rem;
            margin-left: 0px;

            li {
                padding-left: 0px !important;
                padding-top: 0px !important;
            }

            .nav-item {
                padding: 10px;
                text-transform: none;
                float: left;
                flex: unset;

                @media (max-width: 1460px) {
                    padding-bottom: 1em;
                    width: 100%;
                }

                img {
                    float: left;
                    width: 42px;
                    margin-right: 3px;
                    margin-bottom: 5px;
                }

                span {
                    display: block;
                    line-height: 1.4rem;
                    padding-left: 0.7rem;
                    padding-bottom: 1rem;

                    @media (max-width: 1460px) {
                        padding-bottom: 0;
                    }
                }
            }
        }

        ul.link-list-block {
            margin-left: 12px;
            padding-left: 10px;
            padding-right: 20px;
            font-weight: 500;

            li {
                margin-bottom: 1rem;

                &:hover {
                    font-style: normal;
                    letter-spacing: initial;
                    font-weight: 500;
                    text-decoration: none;
                }
            }
        }


        .list-menu a, .dropdown-menu.product-menu a, .link-list-landing-page .link-list-block a {
            text-decoration: none;
            font-weight: 500;
            font-size: 1rem;

            &:hover {
                text-decoration: underline;
            }

            @include media-breakpoint-down(lg) {
                font-size: 1.14rem;
            }
        }

        @include media-breakpoint-down(md) {
            //display: none;
            display: none;
        }
    }



    ul {
        margin-left: -17px;

        @include media-breakpoint-down(lg) {
            margin-left: -15px;
        }

        @media (max-width: 380px) {
            margin-left: 0;
        }

        @include media-breakpoint-down(lg) {
            //margin-left: unset;
        }
    }
}

.navbar-collapse {
    flex: auto;

    .logo_small {
        display: none;

        @include media-breakpoint-down(md) {
            display: inline-block;
            width: 50px;
            margin: 1em;
        }

        @include media-breakpoint-down(sm) {
            display: inline-block;
            width: 25px;
            margin: 0 0 0 15px;
        }
    }
}

.global-nav-link {
    //color: $button-font-color !important;
    //font-weight: 500;
}

.global-nav-link:hover, .global-nav-link:active {
    font-style: normal;
    //font-weight: 600;
    letter-spacing: normal;
    //background-color: $highlight-color;
}

.search-icon {
    fill: $button-font-color;
}


.nav-button {
    position: relative;
    z-index: 1111;
    margin-top: 1.01rem;
    margin-right: 2px;

    @include media-breakpoint-down(sm) {
        margin-top: .6rem;
    }
}

.nav-button #nav-icon3 {
    width: 24px;
    height: 22px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .2s ease-in-out;
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.nav-button #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 26px;
    background: $white;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.nav-button #nav-icon3 span:nth-child(1) {
    top: 0
}

.nav-button #nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
    top: 7px
}

.nav-button #nav-icon3 span:nth-child(4) {
    top: 14px
}

.nav-open #nav-icon3 span:nth-child(1) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open #nav-icon3 span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.nav-open #nav-icon3 span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.nav-open #nav-icon3 span:nth-child(4) {
    top: 9px;
    width: 0;
    left: 50%
}

.nav-open .main-menu {
    opacity: 1;
    visibility: visible;
    background: rgba(0, 0, 0, 0.92);
    height: 100%;
    font-family: $font-family-condensed;
}

.main-menu .nav li a {
    color: $white;
    font-size: 2.8rem;
    font-weight: 500;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
        text-transform: uppercase;
    }
}

.main-menu .nav li {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -moz-animation-timing-function: cubic-bezier(.45,.005,0,1);
    -o-animation-timing-function: cubic-bezier(.45,.005,0,1);
    animation-timing-function: cubic-bezier(.45,.005,0,1);
}

.nav-open .main-menu .nav li {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: -webkit-transform .1s,opacity .1s;
    transition: transform .1s,opacity .1s;
}

.fixed-top {
    display: none;
}

.nav-open .fixed-top {
    display: block;
    overflow: scroll;

    .fe-btn {
        height: 100%;
        border-radius: 0;
    }
}

.tiptop-navbar {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 0.8rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    background-color: $white;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .fe-btn {
        padding: 2px 25px 3px 25px;
        margin-top: 1px;
        margin-bottom: 3px;
    }

    ul {
        display: flex;
        flex-direction: row;
        margin-bottom: 0rem;
        padding-left: 0rem;
        align-self: center;

        li {
            display: list-item;
            padding-top: 0rem;
            font-size: .9rem;
            color: #949494;
            margin-right: 2.5rem;
            list-style-image: none;
            list-style-position: outside;
            list-style-type: none;

            a {
                color: #949494;
            }
        }

        li:last-child {
            margin-right: 0rem;
        }
    }
}

.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


.global-navbar {
    //background: $navbar-color;
    background: #fff;

    @include media-breakpoint-down(md) {
        //display: none;
    }
}

.nav .fe-btn {

}

.global-navbar button {
    font-family: $font-family-compressed;
    font-weight: 700;
    z-index: 999;
    text-transform: uppercase;
}

.global-navbar #navbarSupportedContent {
}

.global-navbar .nav-search-icon {
    width: 49px;
    margin-left: 9px;
}

.bg-white {
    background-color: $white !important;
}

.global-nav {


    li::after {
        content: '';
        float: right;
        right: -2px;
        width: 2px;
        height: 65%;
        margin-top: 8px;
        background: $highlight-color;
        -ms-transform: rotate(20deg); /* IE 9 */
        transform: rotate(20deg);
        margin-left: 1px;
        margin-right: 2px;
    }

    li:last-child::after {
        content: none;
        display: none;
    }

    a.nav-link {
        display: flex;
        align-items: center;
        color: $highlight-color !important;
    }

    .global-nav-link:active a.nav-link {
        color: #ffffff !important;
    }
}


.global-nav-link {
    font-family: $font-family-condensed;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
        font-size: 1.1rem;
        line-height: 1.9rem;
    }

    @media (max-width: 650px) {
        //display: none;
        font-size:.8em;
    }
}


.global-nav-link {
    padding: 10px 16px 11px 16px !important;
    display: inline;

    @include media-breakpoint-up(xl) {
        padding: 11px 19px 10px 18px !important;
    }

    @include media-breakpoint-down(lg) {
        padding: 11px 16px 10px 16px !important;
    }

    @include media-breakpoint-down(sm) {
        padding: .5em .5em !important;
    }
}

.global-nav-link:hover, .global-nav-link:active, .global-nav li:hover, a.nav-link:hover, .global-nav li:hover a.nav-link {
    font-weight: 400;
    color: #313131;
    text-decoration: underline;
    color: $highlight-color;
}

.blue-bg a.nav-link {
    font-weight: 400;
    color: #313131;
    text-decoration: underline;
}

.nav-item:hover .search {
    padding-bottom: 4px;
    border-bottom: 1px solid;
}

.nav-item:hover .search-icon {
    //fill: #ffffff;
}

/*----Global Nav - Desktop----*/
/* adds some margin below the link sets  */




.persona-col:not(:last-child) {

}

.persona {

    span {
        clear: both;
        display: block;

        @include media-breakpoint-down(lg) {
            font-size: .9rem;
        }
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: .2rem;

        @include media-breakpoint-down(lg) {
            font-size: 1.3rem;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        margin-top: 10px;
    }

    .persona-icon {
        max-width: 150px;
        margin-top: -10px;
    }

    .persona-col {
        float: left;
        padding-left: 36px;
        border-right: 3px solid $light-gray;

        img {
            max-height: 36px;
        }
    }

    .persona-slat {

        img {
            margin-top: 0px;
            max-width: 110px;
            margin-right: 10px;
        }
    }

    .persona-col:nth-child(3) {
        border-right: none;
    }
}



.links-landing-list{ 
    li {
        display: inline;
        padding: 0 2rem 0 2rem;
    }
}

.links-landing-list{ 
    lh {
        font-size: 2rem;
        margin-top: -.5rem;
    }
}

/* remove bootstrap dropwdown caret */
.global-nav-link::after {
    display: none;
}
/* shows the dropdown menu on hover */
/*.navbar .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    padding-top: 30px;
    transition: visibility 0s, opacity 0.1s linear;
    min-width: 300px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.07);

    &.double-width-dropdown {
        min-width: 560px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}*/
.navbar .dropdown-menu .showing, .navbar .dropdown .showing {
    display: block;
    visibility: visible;
    opacity: 1;
    padding-top: 30px;
    transition: visibility 0s, opacity 0.1s linear;
    min-width: 300px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.07);
    min-width: 595px !important;

    &.double-width-dropdown {
        min-width: 560px;
    }

    @media (max-width: 1460px) {
        min-width: 400px !important;
        margin-left: -100px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

ul.utility-navigation {
    margin-top: -48px;

    @include media-breakpoint-down(md) {
        margin-top: 0px;
    }


    li {
        font-size: 1.1rem;
        text-transform: uppercase;
        list-style: none;

        a {
            color: #fff;
            text-decoration: none;

            @include media-breakpoint-down(sm) {
                font-size: 1.25rem !important;
                font-weight: 400;
            }
        }

        a:hover {
            color: #fff;
            text-decoration: underline;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 1rem;
        padding-left: 0rem;
    }
}

.get-a-quote-btn {
    width: 150px;
    height: 100px;
    margin: 0 0 0 -20px;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: red;
    overflow: hidden;
    position: relative;
}

.center {
    text-align: center;
}

div.skewed-heading-content {
    -ms-transform: skew(20deg);
    -webkit-transform: skew(20deg);
    transform: skew(20deg);
    position: relative;
    right: -50px;
    background-color: $highlight-color;
    padding: 5px 30px 5px 0;
}

div.skewed-heading-bg {
    background-color: $highlight-color;
    display: inline-block;
    margin-right: 48px;
    padding: 0px 20px 0px 0px;
    -ms-transform: skew(-20deg,0);
    -webkit-transform: skew(-20deg,0);
    transform: skew(-20deg,0);
}

#shape {
    height: 0;
    width: auto;
    border-bottom: 58px solid $highlight-color;
    border-left: 20px solid transparent;
    padding: 0 20px 0 20px;
}


.skew-button a {
    border: none;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;

}

.skew-button {
    background: $highlight-color;
    display: block;
    color: $button-font-color;
    font-weight: normal;
    padding: 10px;
    position: relative;
    text-decoration: none;
    transition: background 0.25s;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    min-width: 155px;
}

.skew-button.triangle:after {
    content: "";
    background-color: $white;
    display: block;
    height: 100%;
    position: absolute;
    left: -10px;
    top: 0;
    width: 22px;
    -webkit-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-15deg);
}


.search {
    width: 22px;
    //height: 22px;
    margin-top: -4px;
    padding-bottom:5px;
}
svg path.search {
    fill: $highlight-color;
}

.search-icon-link {
    margin-top: 0rem;
    padding: 19px 0 14px 25px !important;

    @include media-breakpoint-down(md) {
        padding: 19px 15px 14px 25px !important;
        //display:none !important;
    }

    @include media-breakpoint-down(sm) {
        padding: 5px 0 0 0 !important;
        width:15px;
        //display:none !important;
    }
}

.search-icon {
    fill: #1c4070;
}

.navBTNBig {
    display: flex;

    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.navBTNSmall {
    display: none !important;

    @include media-breakpoint-down(md) {
        display: flex !important;
    }
}

.search-ul {
    .search-li {
        position: relative;
    }

    .search-li:hover {
        font-style: normal !important;
        font-weight: 400 !important;
        letter-spacing: 0rem !important;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        margin-left: auto !important;
        //width: 100%;

        .search-li {
            //margin-right: 0;
        }
    }
}

.navbar-collapse .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    @include media-breakpoint-down(md){
        justify-content: normal;
    }
}

.navbar.global-navbar {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.togglesearch {
    display: none;
    padding-top: 30px;
    padding-bottom: 15px;
    padding-left: 1rem;
    position: absolute;
    border: none;
    background-color: $navbar-dropdown-color;
    width: 561px;
    top: 51px;
    //left: -169px !important;
    right: 0;
    right: 0;
    text-transform: none;
    margin: 0;
    border-radius: 0;
    z-index: 999;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);

    .container {
        padding: 0 0 0 1em !important;

        @include media-breakpoint-down(md) {
            .row {
                form {
                    width: 87%;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0 !important;

            .row {
                form {
                    width: 100%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        //display: block !important;
        //position: relative;
        //top: 0;
        //left: 0;
        right: -15px;
        //width: 100%;
        padding: 1em;
        position: absolute;
        top: 30px;
        //width: 100vw;

        .container {
            form {
                width: 100%;
            }

            .row {
                margin: 0 !important;
                //margin: 0 1em;
            }
        }

        .search-input-wrapper {
            width: 70%;
            margin: 0;
            padding: 0 !important;

            input {
                height: 100%;
                margin: 0;
            }
        }

        .search-button-wrapper {
            width: 30%;
            margin: 0;
            padding: 0 !important;

            .search-button-wrapper {
                width: 100%;
            }
        }
    }

    @media (max-width: 650px) {
        width: 100vw;
    }
}


.togglesearch-text {
    outline: none;
    border-radius: 0rem;
}
.togglesearch-text:focus {
    border-color: $highlight-color;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.togglesearch-btn {
    font-family: $font-family-compressed;
    font-weight: 700;
    font-weight: 700;
    color: $button-font-color;
    text-transform: uppercase;
    background-color: $highlight-color;
    border: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    border-radius: 0rem;
    padding-bottom: 10px;
}

.togglesearch-btn:hover {
    color: $button-font-color;
    background-color: $button-hover-color;
}

.togglesearch-btn:focus {
    color: $body-color;
    -webkit-box-shadow: none;
    box-shadow: none;
}


    .page-link:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    // IE10+ CSS here
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .togglesearch .form-group {
            width: 400px;
        }
    }

.corporate-search-page, .investor-relations-landing, .free-form-page, .investor-events-page {

    ul.corp-search-tab {
        padding-left: 0px;
        border-bottom: 2px solid #b9b9b9 !important;

        @include media-breakpoint-down(md) {
            border-bottom: 0px;
        }

        li {
            list-style: none;
            display: inline-block;
            min-width: 300px;
            border: 2px solid #b9b9b9;
            border-radius: 4.091px 4.091px 0 0;
            border-bottom: 0px;
            margin-right: 27px;


            @include media-breakpoint-down(md) {
                border: 2px solid #b9b9b9;
                border-radius: 4.091px;
                margin-bottom: 17px;
                margin-right: 0px;
                width: 100%;
            }

            &.active {
                background-color: $button-color;

                button {
                    color: $button-font-color;
                }
            }

            button {
                border: none;
                width: 100%;
                padding: 7px;
                background: transparent;
                color: $button-color;

                @include media-breakpoint-down(md) {
                    padding: 10px;
                }
            }
        }
    }


    ul.DomainFilters {
        padding-left: 0px;

        li {
            list-style: none;

            button {
                text-align: left;
                border: none;
                background: transparent;
                color: $highlight-color;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    width: 100%;
                    font-size: 1.4rem;
                    margin-bottom: 5px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .bs-canvas-header {
        background-color: $highlight-color;
    }

    input.form-control {
        width: calc(100% - 156px);
        float: left;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .filter-site-button {
        background: transparent;
        border: none;
    }

    button {


        span {
            padding-left: 5px;
        }
    }
}

.investor-relations-landing, .free-form-page, .investor-events-page {
    .addsearch-button {
        .investorMobileSidebarTitle {
            display: inline-block;
            vertical-align: bottom;
            font-size: 1.2em;
        }

        svg {
            vertical-align: baseline;
        }
    }

    .bs-canvas-content {
        font-size: 1.3em;

        h3 {
            font-size: 1.5em;
        }

        a {
            text-decoration: none;
        }

        li {
            text-decoration: none;
        }
    }
}

.bs-canvas {
    top: 0;
    z-index: 1110;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    transition: margin .4s ease-out;
    -webkit-transition: margin .4s ease-out;
    -moz-transition: margin .4s ease-out;
    -ms-transition: margin .4s ease-out;
}

.bs-canvas-left {
    left: 0;
    margin-left: -100%;
}

.bs-canvas-right {
    right: 0;
    margin-right: -100%;
}

.breadcrumbs-container {
    margin-top: 4px !important;
}

ul.in-page-nav {
    padding-left: 15px;
    padding-top: 10px;
    height: 100%;

    li.is-active-item > a {
        color: #004a83 !important;
        text-decoration: underline;
    }

    li {
        list-style: none;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 10px;

        a {
            text-decoration: none;
        }

        ul > li {
            text-indent: -5px;
        }

        ul > li:before {
            content: "-";
            text-indent: -5px;
        }

        ul {
            li {
                list-style: none;
                font-size: 18px;
                font-weight: 400;
                margin-left: 10px;

                ul {
                    li {
                        display: none;
                    }
                }
            }
        }
    }

    li a:hover {
        text-decoration: underline;
    }
}

.left-nav {
    background: linear-gradient(180deg, #f1f1f1 0%, rgba(255, 255, 255, 1) 100%);
    margin-bottom: 40px;

    a {
        color: #222;
    }

    h3 {
        padding-top: 10px;
        padding-left: 15px;

        a {
            text-decoration: none;
            text-transform: none;
            font-size: 2rem;
        }
    }
}

.product-tabs {
    .nav-tabs {
        border-bottom: 2px solid #b9b9b9 !important;

        @include media-breakpoint-down(md) {
            border-bottom: 0px;

            .nav-link {
                text-align: center !important;
                font-size: 1rem !important;
            }

            .nav-item {

                a.nav-link {
                    padding: 10px;
                }
            }
        }

        li.nav-item {
            border: 2px solid #b9b9b9;
            border-radius: 4.091px 4.091px 0 0;
            border-bottom: 0px;
            margin-right: 34px;
        }

        @include media-breakpoint-down(md) {
            a.nav-link.active {
                background-color: #004983 !important;
            }
        }

        @include media-breakpoint-down(sm) {

            .nav-item {
                border-bottom: 2px solid #b9b9b9 !important;
                border-radius: 4.091px;
                margin-bottom: 17px;
            }
        }
    }
}