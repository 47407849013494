﻿h1 {
    font-family: $font-family-compressed;
    font-size: 2.8rem;
    font-weight: 400;
}

h2 {
    font-family: $font-family-compressed;
    font-weight: 400;
}

h3 {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem !important;
}

h4 {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem;
}


h5 {
    line-height: 1.8rem !important;
    margin-bottom: 1rem !important;
}

a {
    color: $button-color;
    text-decoration: underline;
}

a:hover {
    color: $secondary-gray;
    text-decoration: underline;
    transition: none;
    transition: none;
}
