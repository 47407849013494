﻿.safeHarborSpace {
    margin-bottom: 10px;
    margin-top: 43px;
}

sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
}

sub {
    top: 0.4em;
}

.wrapAll {
    margin-top: 0px;
}

.shoeHornFoundation {
    h1, h2, h3, h4, h5 {
        margin-top: 15px;
        font-family: 'Gotham Cond SSm A', 'Gotham Cond SSm B';
        font-weight: 500;
        font-style: normal;
    }

    h3 {
        font-size: 23px;
        margin-bottom: 25px;
        margin-top: 15px;
    }

    h5 {
        font-size: 18px;
    }

    .button.dropdown > ul {
        border: 1px solid #efefef;
    }

    .button.dropdown > ul li a {
        color: #666;
        font-size: 15px;
        padding: 10px 14px;
    }

    .button.large {
        margin-top: 16px;
        width: 100%;
        padding: 15px 3px 15px 12px;
        font-size: 15px;
        color: #888;
        background-color: #efefef;
        border: 1px solid #efefef;
        text-align: left;
    }

    .button.dropdown.large:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: solid 7px;
        border-color: #999 transparent transparent transparent;
        margin-top: -4px;
        right: 13px;
    }

    .button:active {
        -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, 0) inset;
        -moz-box-shadow: 0 0px 0 rgba(0, 0, 0, 0) inset;
        box-shadow: 0 0px 0 rgba(0, 0, 0, 0) inset;
    }

    ul {
        margin: 0px;
    }

    ul.arInfo li:last-child {
        margin-bottom: 78px;
    }

    ul.arInfo li ul li:last-child {
        margin-bottom: 0px;
    }

    li {
        margin-bottom: 23px;
    }
}


.arCharts {
    margin-bottom: 25px;
}

.arInfo p {
    font-size: 14px;
    margin-bottom: 0px;
}

.arInfo h5 {
    margin-top: 0px;
    margin-bottom: 2px;
}

.arInfo li {
    margin-bottom: 23px;
}

.arInfo li:last-child {
    margin-bottom: 0px;
}

.shoeHornFoundation .documentLibraryVids a img {
    margin: 0px;
}

.flex-video {
    margin-bottom: 0px;
}

ul.notes, ul.table-notes {
    margin: -5px 0px 20px 0px;
    padding-left: 0px;
}

ul.notes li, ul.table-notes li {
    font-size: 11px;
    list-style-type: none;
    margin-bottom: 4px;
    color: #666;
}

ul.arInfo {
    margin-top: 66px;
    margin-left: 0px;
    background-color: #efefef;
    padding: 12px 12px 12px 12px;
}

ul.arInfo li ul {
    padding-left: 0px;
    margin-left: 20px;
}

ul.arInfo li ul li {
    list-style: square;
    margin-bottom: 5px;
}

ul.arInfo li ul li span {
    margin-left: -5px;
    display: inline-block;
}

.flex-video.widescreen {
    padding-bottom: 56.25%;
    padding-top: 0px;
    margin-bottom: 20px;
}

table {
    background-color: #fff;
    border: none;
}

table thead {
    background-color: transparent;
}

table tbody tr:nth-child(odd) {
    background-color: transparent;
}

table tbody tr:nth-child(even) {
    background: transparent;
}


.parenNote {
    font-size: 12px;
    margin-top: -14px;
    color: #666;
}


.arspecifications, .smallSpec {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 0px;
    border-collapse: collapse;
    border-spacing: 0px;
}


.arspecifications tbody td:nth-child(2), .smallSpec tbody td:nth-child(2) {
    padding-right: 10px;
}

.arspecifications tbody td:nth-child(3), .arspecifications tbody th:nth-child(3), .arspecifications thead td:nth-child(3) {
    background: #3b5c8e;
    color: #fff;
    padding-right: 10px;
}

.smallSpec tbody td:nth-child(3), .smallSpec tbody th:nth-child(3), .smallSpec thead td:nth-child(3) {
    background: #3b5c8e;
    color: #fff;
    padding-right: 10px;
}

.arspecifications tbody td:nth-child(3), .smallSpec tbody td:nth-child(3) {
    border-bottom: 1px solid #8493a8;
}

.arspecifications tr.ARsubTite td, .smallSpec tr.ARsubTite td {
    padding: 20px 8px 3px 8px;
}

.arspecifications tr.ARsubTite td, .smallSpec tr.ARsubTite td {
    padding: 20px 8px 3px 3px;
    border-bottom: 1px solid #ccc;
    font-family: 'Gotham Cond SSm A', 'Gotham Cond SSm B';
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
}

.seven.columns.arTable.arHighlight {
    padding-right: 40px;
}

table.arspecifications tbody tr.ARsubTite:first-child td {
    padding-top: 0px;
}

.arspecifications {
    tbody td, .smallSpec tbody td {
        border-bottom: 1px solid #eee;
    }

    tr.finRight td, .smallSpec tr.finRight td {
        text-align: right;
    }

    tr td.finLeft, .smallSpec tr td.finLeft {
        text-align: left;
    }

    tr td.finLeftIndent, .smallSpec tr td.finLeftIndent {
        text-align: left;
        padding-left: 20px;
    }

    td, .smallSpec td {
        font-size: 14px;
        line-height: 15px;
        padding: 6px 3px 6px 3px;
        background-position: bottom;
        border: none;
        white-space: normal;
        text-align: left;
        vertical-align: text-top;
    }

    thead tr td, .smallSpec thead tr td {
        font-family: 'Gotham Cond SSm A', 'Gotham Cond SSm B';
        font-weight: 600;
        font-style: normal;
    }

    thead tr td, .smallSpec thead tr td {
        padding: 6px 3px 0px 6px;
        font-size: 17px;
        line-height: 15px;
        background-position: bottom;
        background-color: transparent;
        vertical-align: middle;
        text-align: left;
    }

    td.headerspan, .smallSpec td.headerspan {
        line-height: 0px !important;
        padding: 0px;
        margin: 0px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .OrderInfoData input[type="file"] {
        width: 80px;
    }

    .SpecInfoData input[type="file"] {
        width: 80px;
    }
}

@media only screen and (max-width: 64.0625em) {
    ul.arInfo {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 40.0625em) {
    ul.arInfo {
        margin-top: 20px;
    }

    .parenNote {
        margin-bottom: 5px;
    }

    .arspecifications td {
        font-size: 11px;
    }

    .arspecifications thead tr td {
        font-size: 12px;
    }

    .arspecifications tr.ARsubTite td {
        font-size: 12px;
    }

    .arspecifications tbody td:nth-child(2) {
        padding-right: 5px;
    }

    .arspecifications tbody td:nth-child(3), .arspecifications tbody th:nth-child(3), .arspecifications thead td:nth-child(3) {
        padding-right: 3px;
    }
}