﻿$primary-nav-background: "/assets/images/gws/GWSHeaderBack.svg";
$secondary-nav-background: "/assets/images/gws/none.svg";
$small-nav-background: "/assets/images/gws/none.svg";
$mobile-nav-background: "/assets/images/gws/mobilewaterheader.svg";

.footer {
    background-color: #1a1a1a;
}

.logo.footer-image svg {
    fill: #fff;
    width: 300px;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
}

.navbar-brand.logo {
    svg {
        fill: #000000;
        width: 300px !important;
        margin: 1em 0 1em 0;
    }
}

a {
    color: #004a83;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);

    @include media-breakpoint-down(sm) {
        margin-left: 1em;
        display: none;
    }

    @include media-breakpoint-down(md) {
        display: none;
        .logo_large {
            display: none;
        }
    }
}

.navbar .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
    //min-width: 595px !important;
}
.navbar .dropdown:hover .dropdown-menu.double-width-dropdown, .navbar .dropdown .dropdown-menu:hover.double-width-dropdown {
    min-width: 950px !important;
}
.global-navbar {
    background: #ffffff;
}

.topblackbar {
    height: 35px;
    background-color: #000000;
    display: block;
    width: 100%;
    text-transform: capitalize;

    @include media-breakpoint-down(md) {
        height: 30px;
    }

    ul {
        flex-direction: row;
        display: -ms-flexbox !important;
        text-align: right;
        margin-bottom: 0;

        .in-page-nav {
            padding-left: 15px;
            padding-top: 10px;
            height: 100%;

            li {
                list-style: none;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;

                a {
                    text-decoration: none;
                }

                ul li {
                    list-style: square;
                    font-size: 18px;
                    font-weight: 400;
                    margin-left: 40px;
                }
            }
        }

        .in-page-nav:first-child {
            background: linear-gradient(180deg, #f1f1f1 0%, rgba(255, 255, 255, 1) 100%);
        }

        @media (max-width: 450px) {
            text-align: right;
            padding: 2px 0;
            display: inline-block;
            float: right;
            font-size: .9em;
        }

        li {
            position: relative;
            color: #ffffff;
            display: inline-block;
            padding: 5px;
            margin-left: 2em;

            &:hover {
                background-color: $highlight-color;
            }

            a {
                text-decoration: none;
                color: #ffffff;
            }

            @include media-breakpoint-down(md) {
                //display: none;
                margin-left: 0;
                font-size: .8em;
            }
        }
    }
}


    .tiptop-navbar {
        .sitewide-call-to-action {

            .fe-btn {
                padding: 2px 12px 3px 12px;

                svg {
                    display: none;
                }

                span.btn-text {
                    padding-right: 0px !important;
                }
            }

            .ffs-green {
                background: none;
                background-color: #3d3d3d;
                border: none;
            }
        }



        ul.utility-navigation.with-button {
            margin-top: 0px !important;
            margin-right: .5rem;
        }
    }

//// hero banner block overwrite

.hero-banner-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: unset;
    margin-right: -50%;
    -webkit-transform: translate(7.5%, -50%) !important;
    -ms-transform: translate(7.5%, -50%) !important;
    transform: translate(7.5%, -50%) !important;

    @include media-breakpoint-up(md) {
        top: 50%;
    }

    @include media-breakpoint-down(sm) {
        transform: translate(0%, -50%) !important;
        margin-right: 0 !important;
    }

    h1 {
        line-height: 2.75rem;
        text-transform: unset !important;
        vertical-align: unset !important;
        text-transform: unset !important;
        text-align: left !important;
        font-size: 2.8rem !important;

        @include media-breakpoint-down(md) {
            font-size: 2rem !important;
        }
    }
}

.hero-banner {
    min-height: 140px;

    @include media-breakpoint-up(md) {
        min-height: 150px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 150px;
    }
}